import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import './sanctionOrderModal.css';

const AddSchemePreview = ({ showPreview, setShowPreview, formData, handleSubmit, activity, subActivity }) => {
    // console.log(formData)

    const handleClose = () => setShowPreview(false);
    const findSubActivitiesById = (id) => {
        let listData = subActivity;
        return listData.filter(subActivity => subActivity.activity_id === id);
    };
    return (
        <div>
            <Modal
                size="lg"
                show={showPreview} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Scheme Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body className='previewModalBody'>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Dept. Name</th>
                                <th>Scheme Name</th>
                                <th>Scheme Code.</th>
                                <th>Scheme Type</th>
                                <th>Grant Code</th>
                                <th>Financial Year</th>
                                <th>Carry Forward</th>
                                <th>Total Scheme Budget</th>
                                <th>Scheme Doc Uploaded</th>
                                {/* <th>Account Name</th>
                                <th>Account Type</th>
                                <th>Account No</th>
                                <th>Bank</th>
                                <th>Branch</th>
                                <th>IFSC Code</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{formData.department}</td>
                                <td>{formData.name}</td>
                                <td>{formData.code}</td>
                                <td>{formData.type}</td>
                                <td>{formData.grant_code}</td>
                                <td>{formData.financial_year}</td>
                                <td>{formData.carry_forwarded == 1 ? "Yes" : "No"}</td>
                                <td>{formData.department}</td>
                                <td>{formData.attachment && formData.attachment.length ? "Yes" : "No"}</td>
                                {/* <td>{formData.bank.account_name?formData.bank.account_name:''}</td>
                                <td>{formData.bank.account_type?formData.bank.account_type:''}</td>
                                <td>{formData.bank.account_no?formData.bank.account_no:''}</td>
                                <td>{formData.bank.bank_name?formData.bank.bank_name:''}</td>
                                <td>{formData.bank.branch_name?formData.bank.branch_name:''}</td>
                                <td>{formData.bank.ifsc_code?formData.bank.ifsc_code:''}</td> */}
                            </tr>
                        </tbody>
                    </Table>
                    <h6>Sub Head List:</h6>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>SubHead Name</th>
                                <th>SubHead Code</th>
                                <th>Budget Date</th>
                                <th>Budget</th>
                                <th>Provisional Budget</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                formData.sub_heads && formData.sub_heads.map((subhead, i) => {
                                    return (<>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{subhead.name}</td>
                                            <td>{subhead.code}</td>
                                            <td>{subhead.budget_date}</td>
                                            <td>{subhead.budget}</td>
                                            <td>{subhead.provisional_budget}</td>
                                        </tr>
                                        {formData.is_activity_active == '1' && <tr>
                                            <td colSpan="6">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Activity</th>
                                                            <th>Subactivity</th>
                                                            <th>Provisional Budget</th>
                                                            <th>Release Budget</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {subhead.activity && subhead.activity.map((activityData, activityIndex) => (
                                                            <tr key={activityIndex}>
                                                                <td>
                                                                    <select
                                                                        className="form-control"
                                                                        name='activity_id'
                                                                        value={activityData.activity_id} disabled={true}>
                                                                        <option value="">---Select---</option>
                                                                        {activity.map(e => (
                                                                            <option key={e.id} value={e.id}>{e.activity}</option>
                                                                        ))}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="form-control"
                                                                        name='subactivity_id'
                                                                        value={activityData.subactivity_id}
                                                                        disabled={true}>
                                                                        <option value="">---Select---</option>
                                                                        {findSubActivitiesById(activityData.activity_id).map(e => (
                                                                            <option key={e.id} value={e.id}>{e.subactivity}</option>
                                                                        ))}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        name="provisional_budget"
                                                                        value={activityData.provisional_budget || ''}
                                                                        disabled={true}

                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        name="budget"
                                                                        value={activityData.budget || ''}
                                                                        disabled={true}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>}
                                    </>

                                    );
                                })
                            }

                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Change
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        SUBMIT
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddSchemePreview