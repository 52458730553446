import React, { useState, useEffect, useRef } from 'react'
import { Form, Modal } from 'react-bootstrap';
import {
    get_schemes,
    get_departments,
    get_schemes_by_fy,
    get_financial_year,
    UploadAllocateBudgetDoc,
    getAllocateBudgetList,
    getActivity,
    getSubActivity,
    approve_allocate_budget
} from '../../../helper/Api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { ADD_CHALLAN_FAIL } from "../../../RTK/Slices/ChallanSlice";
import AddBudgetPreview from '../../../components/Multi/Model/AddBudgetPreview';
import { DeleteForever } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import './ViewAllocateBudget.css';
const ViewBudget = () => {
    const navigate = useNavigate();
    const { viewId, approveId } = useParams();
    const [showPreview, setShowPreview] = useState(false);
    const [fy_list, set_fy_list] = useState([]);
    const [schemeList, setSchemesList] = useState([]);
    const [activity, setActivity] = useState([]);
    const [subActivity, setSubActivity] = useState([]);
    const [scheme, setScheme] = useState({});
    const [subHead, setSubHead] = useState([]);
    const [department, setDepartment] = useState([]);
    const { authData } = useSelector((state) => state.authData);
    const [subheadBudget, setSubheadBudget] = useState([]);
    const subheadBudgetUsed = useRef([]);
    const [selectedDepartment, setSelectedDepartment] = useState({});
    const [selectedSubhead, setSelectedSubhead] = useState({});
    const subheadBudgetTotal = useRef({
        allocateProvision: 0,
        allocateRelease: 0,
    });
    const [formData, setFormData] = useState({
        id: "",
        budget_order_no: "",
        budget_order_date: "",
        provision: 0,
        release: 0,
        scheme_id: null,
        financial_year_id: null,
        supporting_attachment: [],
        order_attachment: [],
        l1_remark: "",
        l2_remark: "",
        l3_remark: "",
        l2_status: "",
        l3_status: "",
        total_scheme_provisional_allocation: 0,
        total_scheme_released_allocation: 0,
        subheads: [],
        department_Array: [
            {
                department_id: "",
                department_level: "",
                department_name: "",
                subHead: [{
                    subhead_id: "",
                    name: "",
                    code: "",
                    provisional_budget: 0,
                    release_budget: 0,
                    balance: 0,
                    approveBudget: 0,
                    santioned: 0,
                    release_Balance: 0,
                    release_Balance: 0,
                    utilised_budget: 0,
                    activity: [{
                        activity_id: '', subactivity_id: '', provisional_budget: "",
                        budget: "",
                    }],
                    activities: [],
                }],
            }],
        parent_department_id: authData.user.department_id,
        budget_order_doc_url: "",
        budget_supporting_doc_url: "",
    });
    const fetchFinancialYears = async () => {
        const fy = await get_financial_year();
        if (fy.data.status) {
            set_fy_list(fy.data.list)
        }
    }
    const dispatch = useDispatch();
    useEffect(() => {
        fetchDepartments();
        fetchActivity();
        fetchSubActivity();
        fetchSchemes({});
        fetchFinancialYears();
        fetchAllocateBudget();
    }, [viewId])
    const fetchSchemes = async () => {
        try {
            const resp = await get_schemes({ approved: true });
            if (resp.data.status) {
                setSchemesList(resp.data.schemes);
            }
        } catch (error) {
            setSchemesList([]);
        }
    }
    const schemeDetailByfy = async (scheme_id, financial_year_id) => {
        try {
            const resp = await get_schemes_by_fy(scheme_id, financial_year_id);
            if (resp.data.status) {
                if (resp.data.schemes.length != 0) {
                    setScheme(resp.data.schemes[0]);
                    setSubHead(resp.data.schemes[0].sub_heads_list);
                }
            }
        } catch (error) {
            // console.log(error);
            setScheme({})
        }
    }
    const fetchDepartments = async () => {
        try {
            let newDepartmentLevel = authData.user.department_details.department_level;
            if (newDepartmentLevel === "T1") {
                newDepartmentLevel = "T2";
            } else if (newDepartmentLevel === "T2") {
                newDepartmentLevel = "T3";
            }
            const departments = await get_departments({ department_level: newDepartmentLevel });
            if (departments.data.status) {
                setDepartment(departments.data.result);
            }
        } catch (error) {
            console.log("departments", error);
        }
    }
    const fetchAllocateBudget = async () => {
        try {
            let subheadArray = [];
            let newDepartmentLevel = authData.user.department_details.department_level;
            if (newDepartmentLevel === "T1") {
                newDepartmentLevel = "T2";
            } else if (newDepartmentLevel === "T2") {
                newDepartmentLevel = "T3";
            }
            const departments = await get_departments({ department_level: newDepartmentLevel });
            const { data } = await getAllocateBudgetList({ id: viewId });
            let totalProvisionalBudgetOverall = 0;
            let totalReleaseBudgetOverall = 0;
            let department_Array = [];
            const resp = await get_schemes_by_fy(data.result[0].scheme_id, data.result[0].financial_year_id);
            if (resp.data.status) {
                if (resp.data.schemes.length != 0) {
                    setScheme(resp.data.schemes[0]);
                    setSubHead(resp.data.schemes[0].sub_heads_list);
                    subheadArray = resp.data.schemes[0].sub_heads_list;
                }
            }
            data.result[0].budget_details.forEach(item => {
                let departmentObj = departments.data.result.find(d => d.id === item.department_id);
                let departmentCheck = department_Array.find(d => d.department_id === item.department_id);
                if (!departmentCheck) {
                    departmentCheck = {
                        department_id: item?.department_id,
                        department_level: departmentObj?.department_level,
                        department_name: departmentObj?.name,
                        subHead: []
                    };
                    department_Array.push(departmentCheck);
                }
                let totalProvisionalBudget = 0;
                let totalReleaseBudget = 0;
                const subheadActivity = subheadArray.find(d => d.id === item.subhead_id);
                const activities = item.activities.map(activity => {
                    const subheadItems = subheadActivity.activities.find(d => d.subhead_activity_id === activity.subhead_activity_id && d.subhead_subactivity_id === activity.subhead_subactivity_id);
                    totalProvisionalBudget += parseFloat(activity.provisional_budget);
                    totalReleaseBudget += parseFloat(activity.budget); // Assuming 'budget' is the release budget
                    return {
                        activity_id: activity.subhead_activity_id,
                        subactivity_id: activity.subhead_subactivity_id,
                        provisional_budget: activity.provisional_budget,
                        budget: activity.budget,
                        subhead_provisional_budget: subheadItems.provisional_budget,
                        subhead_budget: subheadItems.budget,
                    };
                });
                console.log('subhead', item);
                const subHead = {
                    subhead_id: item.subhead_id,
                    name: item.name,
                    code: item.code,
                    provisional_budget: subheadActivity.provisional_budget,
                    release_budget: subheadActivity.budget,
                    approveBudget: item.budget,
                    santioned: 0,
                    utilised_budget: subheadActivity.utilised_budget || 0,
                    release_Balance: 0,
                    balance: subheadActivity.balance,
                    allocate_provision: totalProvisionalBudget,
                    allocate_release: totalReleaseBudget,
                    activity: activities,
                    activities: item.activities,
                };
                departmentCheck.subHead.push(subHead);
                totalProvisionalBudgetOverall += parseFloat(totalProvisionalBudget);
                totalReleaseBudgetOverall += parseFloat(totalReleaseBudget);
            });
            setFormData({
                id: data.result[0].id,
                budget_order_no: data.result[0].budget_order_no,
                budget_order_date: data.result[0].budget_order_date,
                scheme_id: data.result[0].scheme_id,
                financial_year_id: data.result[0].financial_year_id,
                supporting_attachment: [],
                order_attachment: [],
                provision: data.result[0]?.total_scheme_provisional_allocation,
                release: data.result[0]?.total_scheme_released_allocation,
                l1_remark: data.result[0].l1_remark,
                l2_remark: data.result[0].l2_remark,
                l3_remark: data.result[0].l3_remark,
                l2_status: data.result[0].l2_status,
                l3_status: data.result[0].l3_status,
                total_scheme_provisional_allocation: data.result[0].total_scheme_provisional_allocation,
                total_scheme_released_allocation: data.result[0].total_scheme_released_allocation,
                subheads: [],
                budget_order_doc_url: data.result[0].budget_order_doc_url,
                budget_supporting_doc_url: data.result[0].budget_supporting_doc_url,
                department_Array: department_Array,
            })
            subheadBudgetTotal.current = {
                allocateProvision: totalProvisionalBudgetOverall,
                allocateRelease: totalReleaseBudgetOverall
            };
            console.log('formData', formData);
        } catch (error) {
            console.log("allocate budget", error);
        }
    }
    const fetchActivity = async () => {
        try {
            const resp = await getActivity();
            if (resp.data.status) {
                setActivity(resp.data.result);
            }
        } catch (error) {
            setActivity([]);
        }
    }
    const fetchSubActivity = async () => {
        try {
            const resp = await getSubActivity();
            if (resp.data.status) {
                setSubActivity(resp.data.result);
            }
        } catch (error) {
            setSubActivity([]);
        }
    }
    const findSubActivitiesById = (id) => {
        let listData = subActivity;
        return listData.filter(subActivity => subActivity.activity_id === id);
    };
    const handleSubmit = async (status) => {
        try {
            // anand sir please write code of approve case
            let payload = {
                l2_remark: formData.l2_remark,
                l3_remark: formData.l3_remark,
                l2_status: formData.l2_status,
                l3_status: formData.l3_status
            };
            if (authData.user.user_type.toLowerCase() == 'l2') {
                payload.l2_status = status
            }
            if (authData.user.user_type.toLowerCase() == 'l3') {
                payload.l3_status = status
            }

            const response = await approve_allocate_budget(payload, formData.id);
            if (response.data.status) {
                // rajat please add close popup code and show success or error message
                setShowPreview(false);
                navigate('/allocate-budgets');
            }
        } catch (error) {
            console.log(error);
        }
    }
    const previewhandler = (e) => {
        e.preventDefault();
        setShowPreview(true);
    };
    const handleFormData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({
            ...formData,
            [name]: value,
        });
        console.log(formData);
    }
    return (
        <>
            <AddBudgetPreview showPreview={showPreview} setShowPreview={setShowPreview} formData={formData}
                handleSubmit={handleSubmit} scheme={scheme} subheadsList={subHead} financialYears={fy_list}
                department={department} activity={activity} subActivity={subActivity} subheadBudgetTotal={subheadBudgetTotal} pageType={'View'}/>
            <div>
                <div className="p-3">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <h4>View Allocate Budget</h4>
                            </div>
                            <div className="card p-3">
                                <div className="row">
                                    <div className="col-md-8 offset-md-2">
                                        <div className="row">
                                            <div className="col-sm-12 p-2">
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <select
                                                            className="form-control"
                                                            name="scheme_id"
                                                            value={formData.scheme_id}
                                                            disabled={true}>
                                                            <option>Scheme Code</option>
                                                            {
                                                                schemeList.map((option) => {
                                                                    return (
                                                                        <option value={option?.id}>{option?.code}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <select
                                                            className="form-control"
                                                            name="financial_year_id"
                                                            value={formData.financial_year_id}
                                                            disabled={true}>
                                                            <option value="">Financial Year</option>
                                                            <option value="">---select year---</option>
                                                            {
                                                                fy_list.map((fy) => {
                                                                    return (
                                                                        <option
                                                                            value={fy.id}
                                                                        // selected={fy.id == fy_id ? true : false}
                                                                        >{fy.year}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {formData.scheme_id && formData.financial_year_id && <div className="card p-3 mt-3">
                                <div className="row">
                                    {/* <h5 className="text-center">Scheme</h5> */}
                                    <div className="table-responsive custom-table" >
                                        <table className="table table-bordered" style={{ borderColor: 'gray' }}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Scheme Code</th>
                                                    <th scope="col">Scheme Name</th>
                                                    <th scope="col">Type of Scheme</th>
                                                    <th scope="col">Provisioned</th>
                                                    <th scope="col">Released</th>
                                                    <th scope="col">Under Approval</th>
                                                    <th scope="col">Sanctioned</th>
                                                    <th scope="col">Released Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{scheme.code}</td>
                                                    <td>{scheme.name}</td>
                                                    <td>{scheme.type}</td>
                                                    <td>{scheme.provisional_budget}</td>
                                                    <td>{scheme.total_budget}</td>
                                                    <td>{scheme.utilised_budget}</td>
                                                    <td>0</td>
                                                    <td>{scheme.balance}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row form-mt-3">
                                    <div className="col-md-8 offset-md-2 mb-3">
                                        <div className="row">
                                            <div className="col-sm-6 d-flex align-items-center">
                                                <label htmlFor="input1" style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Budget Order Ref No</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="budget_order_no"
                                                    value={formData.budget_order_no}
                                                    disabled={true} />
                                            </div>
                                            <div className="col-sm-6 d-flex align-items-center">
                                                <label htmlFor="input2" style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Budget Order Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="budget_order_date"
                                                    value={formData.budget_order_date}
                                                    disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 offset-md-2 mb-3">
                                        <div className="row">
                                            <div className="col-sm-6 d-flex align-items-center">
                                                {!formData?.budget_order_doc_url && <><label htmlFor="input1" style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Attach Budget Order</label>
                                                    <input type="file"
                                                        className='form-control'
                                                        name='order_attachment'
                                                        accept=".pdf,.jpeg,.jpg,.png" multiple /></>}
                                                {formData?.budget_order_doc_url != null && formData?.budget_order_doc_url && <a className="mt-2 mx-2" href={formData?.budget_order_doc_url}
                                                    target='_blank'>order_attachment</a>}
                                            </div>
                                            <div className="col-sm-6 d-flex align-items-center">
                                                {!formData?.budget_supporting_doc_url && <><label htmlFor="input2" style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Attach Supporting</label>
                                                    <input type="file"
                                                        className='form-control'
                                                        name='supporting_attachment'
                                                        accept=".pdf,.jpeg,.jpg,.png" multiple /></>}
                                                {formData?.budget_supporting_doc_url != null && formData?.budget_supporting_doc_url && <a className="mt-2 mx-2" href={formData?.budget_supporting_doc_url}
                                                    target='_blank'>supporting_attachment</a>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 offset-md-2 mb-3">
                                        <div className="row">
                                            <div className="col-sm-6 d-flex align-items-center">
                                                <label htmlFor="input1" style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Provisioned Amount</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="provision"
                                                    value={formData.provision}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="col-sm-6 d-flex align-items-center">
                                                <label htmlFor="input1" style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Released Amount</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="release"
                                                    value={formData.release}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 offset-md-2 mb-3">
                                        <div className="row">
                                            <div className="col-sm-12 p-2">
                                                <div className="form-group">
                                                    <label>L1 Remark *</label>
                                                    <textarea id="remarl1" className="form-control" rows="2" name="l1_remark" value={formData.l1_remark} disabled={true}></textarea>
                                                </div>
                                            </div>
                                            {(authData.user.user_type=='l2' || authData.user.user_type=='l3') && <div className="col-sm-12 p-2">
                                                <div className="form-group">
                                                    <label>L2 Remark *</label>
                                                    <textarea id="remarl2" className="form-control" rows="2" name="l2_remark" value={formData.l2_remark} onChange={handleFormData}></textarea>
                                                </div>
                                            </div>}
                                            {authData.user.user_type=='l3' && <div className="col-sm-12 p-2">
                                                <div className="form-group">
                                                    <label>L3 Remark *</label>
                                                    <textarea id="remarl3" className="form-control" rows="2" name="l3_remark" value={formData.l3_remark} readOnly={true}></textarea>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                {formData.department_Array.map((item, index) => (<div class="card p-3">
                                    <>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="dropdown1" className='bold'>Select Department Code</label>
                                                    <select class="form-control" id="dropdown1" value={item.department_id} disabled={true}>
                                                        <option value="">---Select---</option>
                                                        {department.map((option) => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <table class="table table-striped table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th className='bold text-center'>Level</th>
                                                            <th className='bold text-center'>Department Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center">{item.department_level}</td>
                                                            <td className="text-center">{item.department_name}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {item.subHead.map((subhead, subheadIndex) => (<div class="card p-3">
                                                <div className="table-responsive custom-table">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Sub-Head Code</th>
                                                                <th scope="col">Sub-Head Name</th>
                                                                <th scope="col">Provisioned</th>
                                                                <th scope="col">Released</th>
                                                                <th scope="col">Under Approval</th>
                                                                <th scope="col">Sanctioned</th>
                                                                <th scope="col">Released Balance</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr key={subheadIndex}>
                                                                <td>
                                                                    <select
                                                                        className="form-control"
                                                                        name='code'
                                                                        value={subhead.subhead_id}
                                                                        disabled={true}
                                                                    >
                                                                        <option value="">---Select---</option>
                                                                        {subHead.map((option) => (
                                                                            <option key={option.id} value={option.id}>
                                                                                {option.code}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </td>
                                                                <td>{subhead.name}</td>
                                                                <td>{subhead.provisional_budget | 0}</td>
                                                                <td>{subhead.release_budget | 0}</td>
                                                                <td>{subhead.utilised_budget - subhead.approveBudget | 0}</td>
                                                                <td>0</td>
                                                                <td>{Number(subhead.balance) + Number(subhead.approveBudget) | 0}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="8">
                                                                    <div className='row'>
                                                                        <div className="table-responsive custom-table">
                                                                            <table className="table table-bordered">
                                                                                <thead className="text-center-custom">
                                                                                    <tr>
                                                                                        <th scope="col">Activity</th>
                                                                                        <th scope="col">Subactivity</th>
                                                                                        <th scope="col">Provisional Budget</th>
                                                                                        <th scope="col">Provisioned Allocation</th>
                                                                                        <th scope="col">Release Budget</th>
                                                                                        <th scope="col">Released Allocation</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className="text-center-custom">
                                                                                    {subhead.activity.map((activityData, activityIndex) => (
                                                                                        <tr key={activityIndex}>
                                                                                            <td>
                                                                                                <select
                                                                                                    className="form-control"
                                                                                                    name='activity_id'
                                                                                                    value={activityData.activity_id}
                                                                                                    disabled={true}
                                                                                                >
                                                                                                    <option value="">---Select---</option>
                                                                                                    {activity.map(activity => {
                                                                                                        const exists = subhead.activities.find(e => e.subhead_activity_id == activity.id);
                                                                                                        if (exists) {
                                                                                                            return (
                                                                                                                <option key={activity.id} value={activity.id}>{activity.activity}</option>
                                                                                                            )
                                                                                                        }
                                                                                                    })}
                                                                                                </select>
                                                                                            </td>
                                                                                            <td>
                                                                                                <select
                                                                                                    className="form-control"
                                                                                                    name='subactivity_id'
                                                                                                    value={activityData.subactivity_id}
                                                                                                    disabled={true}
                                                                                                >
                                                                                                    <option value="">---Select---</option>
                                                                                                    {findSubActivitiesById(activityData.activity_id).map(e => {
                                                                                                        const exists = subhead.activities.find(ea => ea.subhead_activity_id == activityData.activity_id && ea.subhead_subactivity_id == e.id);
                                                                                                        if (exists) {
                                                                                                            return (
                                                                                                                <option key={e.id} value={e.id}>{e.subactivity}</option>
                                                                                                            )
                                                                                                        }
                                                                                                    })}
                                                                                                </select>
                                                                                            </td>
                                                                                            <td>
                                                                                                <input
                                                                                                    type="number"
                                                                                                    className="form-control"
                                                                                                    value={activityData.subhead_provisional_budget | 0}
                                                                                                    disabled={true}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <input
                                                                                                    type="number"
                                                                                                    className="form-control"
                                                                                                    name='provisional_budget'
                                                                                                    placeholder="Enter Provision Budget"
                                                                                                    value={activityData.provisional_budget}
                                                                                                    disabled={true}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <input
                                                                                                    type="number"
                                                                                                    className="form-control"
                                                                                                    value={Number(activityData.subhead_budget) + Number(activityData.budget) | 0}
                                                                                                    disabled={true}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <input
                                                                                                    type="number"
                                                                                                    className="form-control"
                                                                                                    name='budget'
                                                                                                    placeholder="Enter Budget"
                                                                                                    value={activityData.budget}
                                                                                                    disabled={true}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="table-responsive custom-table">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th scope="col">Provision</th>
                                                                    <th scope="col">Release</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>Subhead Initial</td>
                                                                    <td>{subhead.provisional_budget | 0}</td>
                                                                    <td>{Number(subhead.balance) + Number(subhead.approveBudget) | 0}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>Total Allocation</td>
                                                                    <td>{subhead?.allocate_provision | 0}</td>
                                                                    <td>{subhead?.allocate_release | 0}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>Budget Balance</td>
                                                                    <td>{subhead.provisional_budget - subhead?.allocate_provision | 0}</td>
                                                                    <td>{(Number(subhead.balance) + Number(subhead.approveBudget)) - subhead?.allocate_release | 0}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>))}
                                        </div>
                                    </>
                                </div>))}
                                <div className='row justify-content-center'>
                                    <div className='col-md-8'>
                                        <table className="table table-bordered mx-auto w-90">
                                            <thead className="text-center-custom">
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">Provisioned</th>
                                                    <th scope="col">Released</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-center-custom">
                                                <tr>
                                                    <td className='bold'>Initial Scheme Balance</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name='provisionalBudget'
                                                            readOnly={true}
                                                            value={scheme.provisional_budget}
                                                            placeholder="Enter Provision Budget" />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name='releasedBudget'
                                                            readOnly={true}
                                                            value={scheme.balance + Number(formData.total_scheme_released_allocation)}
                                                            placeholder="Enter Release Budget" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='bold'>Total Allocation of Scheme</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name='provisionalBudget'
                                                            readOnly={true}
                                                            value={subheadBudgetTotal.current.allocateProvision || 0}
                                                            placeholder="Enter Provision Budget" />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name='releasedBudget'
                                                            readOnly={true}
                                                            value={subheadBudgetTotal.current.allocateRelease || 0}
                                                            placeholder="Enter Release Budget" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='bold'>Scheme Balance</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name='provisionalBudget'
                                                            readOnly={true}
                                                            value={scheme.provisional_budget - subheadBudgetTotal.current.allocateProvision || 0}
                                                            placeholder="Enter Provision Budget" />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name='releasedBudget'
                                                            readOnly={true}
                                                            value={(scheme.balance + Number(formData.total_scheme_released_allocation)) - subheadBudgetTotal.current.allocateRelease || 0}
                                                            placeholder="Enter Release Budget" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {(authData.user.user_type=='l2'||authData.user.user_type=='l3') && <div class="row mt-3 justify-content-center">
                                    <div class="col-auto">
                                        <button type="button" class="btn btn-danger mx-2" onClick={previewhandler}>Reject</button>
                                        <button type="button" class="btn btn-primary mx-2" onClick={previewhandler}>Approve</button>
                                    </div>
                                </div>}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ViewBudget
