import axios from "axios";

// const URL=process.env.REACT_APP_BACKEND_URL;
// const API = axios.create({ baseURL: 'http://localhost/ITDA-BACKEND-CI/' });
const API = axios.create({baseURL: "https://multiapi.wecdfms.org/"});

// Create a function to handle API errors
const handleApiError = (error) => {
    // Check if error is due to unauthorized access (e.g., token expired)
    if (error.response && error.response.status === 401) {
        // Redirect to the login page
        console.warn(error);
        // window.location.href = "/login";
    }
    // You can handle other errors here if needed
    return Promise.reject(error);
};

// Add a response interceptor to handle global API errors
API.interceptors.response.use(response => {
    // If the request was successful, return the response
    return response;
}, error => {
    console.warn(error);
    // If an error occurs, handle it using the handleApiError function
    return handleApiError(error);
});

export const setAuthToken = (token) => {
    // token = JSON.parse(token);
    if (token) {
        API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete API.defaults.headers.common['Authorization'];
    }
};

//user
export const login_user = (FormData) => API.post('users/site_login', FormData);
export const login_user_via_send_otp = (FormData) => API.post('users/user_send_otp', FormData);
export const login_user_via_verify_otp = (FormData) => API.post('users/user_verify_otp', FormData);
export const forget_password_via_verify_otp = (FormData) => API.post('users/forget_verify_otp', FormData);
export const get_users = (formData) => API.get('users/user', {
    params: {
        department_id: formData.department_id || '',
        searchStr: formData.searchStr || '',
        department_level: formData.department_level || '',
    }
});
export const get_user_by_id = (id) => API.get(`users/user/${id}`);
export const add_user = (formData) => API.post('users/user', formData);
export const update_user_by_id = (formData, id) => API.post(`users/update_user/${id}`, formData);
export const reset_user_by_id = (id) => API.post(`users/user_reset/${id}`);
export const delete_user_by_id = (formData, id) => API.post(`users/delete_user/${id}`, formData);

//scheme
export const get_schemes = (data) => API.get('schemes/scheme', {
    params: {
        from_date: data?.from_date || '',
        to_date: data?.to_date || '',
        search_str: data?.search_str || '',
        fy_id: data?.fy_id || '',
        approved: data?.approved || '',
        id: data?.id || '',
    }
});
export const get_schemes_details_by_id = (id) => API.get(`schemes/scheme/${id}`);
export const add_schemes = (formData) => API.post('schemes/scheme', formData);
export const update_scheme_by_id = (formData, id) => API.post(`schemes/update_scheme/${id}`, formData);
export const delete_scheme_by_id = (formData) => API.post('schemes/delete_scheme', formData);
export const update_scheme_status = (formData) => API.post('schemes/scheme_status_update', formData);
export const add_budget = (formData) => API.post('schemes/add_budget', formData);
export const update_budget_by_id = (formData) => API.post('schemes/update_budget', formData);
export const delete_budget_by_id = (formData) => API.post('schemes/delete_budget', formData);
export const get_budget_list = (formData, id) => API.post(`schemes/get_budget_list/${id}`, formData);
export const update_budget_status = (formData) => API.post('schemes/budget_status_update', formData);
export const generate_xml_of_payment_by_scheme_id = (formData) => API.post('schemes/generate_xml_of_scheme_payment', formData);
export const download_xml_as_zip = () => API.get('schemes/download_xml_as_zip');

export const get_schemes_by_fy = (scheme_id = 0, fy_id = 0) => API.get(`schemes/scheme_details_by_fy/${scheme_id}/${fy_id}`);
export const get_schemes_by_fy_added_pending = (scheme_id = 0, fy_id = 0) => API.get(`schemes/scheme_details_by_fy_added_pending/${scheme_id}/${fy_id}`);

//beneficiary
export const get_beneficiary = (data) => API.get('beneficiary/beneficiary', {
    params: {
        from_date: data?.from_date || '',
        to_date: data?.to_date || '',
        search_str: data?.search_str || '',
        department_id: data?.department_id || ''
    }
});
export const get_beneficiary_by_id = (id) => API.get('beneficiary/beneficiary/' + id);
export const add_beneficiary = (formData) => API.post('beneficiary/beneficiary', formData);
export const update_beneficiary_by_id = (formData) => API.post('beneficiary/update_beneficiary', formData);
export const delete_beneficiary_by_id = (formData) => API.post('beneficiary/delete_beneficiary', formData);
export const update_beneficiary_status = (formData) => API.post('beneficiary/beneficiary_status_update', formData);


//sanction order
export const add_sanction_order = (formData) => API.post('sanctions_order/sanction_order', formData);
export const get_invoices_by_invoice_no = (formData) => API.post('sanctions_order/invoice_list_by_invoice_no', formData);
export const get_invoice_details_by_invoice_id = (invoice_id) => API.get(`sanctions_order/invoice_details/${invoice_id}`);


// fund invocie list
export const get_fund_invoices = (data) => API.post(`sanctions_order/invoices`, data);
export const get_disbursment_invoices = (data) => API.post('sanctions_order/disbursment_invoice', data);
export const get_payment_invoices = (data) => API.post('sanctions_order/payment_invoices', data);
export const update_invoice_approvalStatus = (formData) => API.post('sanctions_order/update_invoice_approval_status', formData);
export const update_invoice_paymentStatus = (formData) => API.post('sanctions_order/update_invoice_payment_status', formData);
export const update_invoice = (formData) => API.post('sanctions_order/edit_invoice', formData);

// dashboard
export const get_dashboard_data = (fy_id) => API.get(`dashboard/dashboard?fy_id=${fy_id}`);
// export const add_account_balance_interest = (formData) => API.post('dashboard/add_account_balance_interest', formData);
export const add_account_balance_interest = (formData) => API.post('v2/schemes/create_scheme_account', formData);
export const update_account_balance_interest = (formData, id) => API.post(`v2/schemes/update_scheme_account/${id}`, formData);
export const get_account_balance_interest = () => API.get('dashboard/account_balance_interest',);

// utils
export const get_financial_year = () => API.get('utils/financial_year');
export const get_scheme_type = () => API.get('utils/scheme_type');
export const get_beneficiary_status = () => API.get('utils/beneficiary_status');
export const get_states = () => API.get('utils/states');
export const get_districts_list = (state_id) => API.get(`utils/districts/${state_id}`);
export const send_otp = (formData) => API.post(`utils/send_otp`, formData);
export const verify_otp = (formData) => API.post(`utils/verify_otp`, formData);

// budget reappropriate
export const add_budget_reappropriate = (formData) => API.post(`v2/BudgetReappropriate/create`, formData);
export const get_budget_reappropriate = () => API.get(`v2/BudgetReappropriate/list`);
export const get_single_budget_reappropriate = (id) => API.get(`v2/BudgetReappropriate/single/${id}`);
export const add_budget_reappropriate_subhead = (formData, id) => API.post(`v2/BudgetReappropriate/create_to_subhead/${id}`, formData);

export const add_beneficiary_reimbursement = (formData) => API.post(`v2/BeneficiaryReimbursement/create`, formData);
export const add_beneficiary_reimbursement_beneficiaries = (formData, id) => API.post(`v2/BeneficiaryReimbursement/create_beneficiary_details/${id}`, formData);
export const get_beneficiary_reimbursement = (data) => API.get(`v2/BeneficiaryReimbursement/list`, {
    params: {
        from_date: data.from_date,
        to_date: data.to_date,
        search_str: data.search_str || '',
        dateColumn: data.dateColumn || '',
        listType: data.listType || '',
    }
});
export const get_single_beneficiary_reimbursement = (id) => API.get(`v2/BeneficiaryReimbursement/single/${id}`);
export const update_beneficiary_reimbursement_approval = (formData, id) => API.post(`v2/BeneficiaryReimbursement/approval_status/${id}`, formData);
export const update_beneficiary_reimbursement_payment = (formData, id) => API.post(`v2/BeneficiaryReimbursement/payment_status/${id}`, formData);

// Challan
export const getHeadOfAccounts = () => API.get(`v2/Headofaccounts`);
export const addChallan = (formData) => API.post('v2/Challans', formData);
export const addDepository = (formData) => API.post('v2/Depository/create', formData);
export const addBeneficiaryAccount = (formData) => API.post('beneficiary/beneficiary_bank', formData);
export const changeBeneficiaryStatus = (id, status) => API.post(`beneficiary/change_account_status/${id}/${status}`);
export const changeBeneficiaryAccountStatus = (formData) => API.post(`beneficiary/change_account_approval_status`, formData);
export const getChallans = () => API.get(`v2/Challans`);

export const getInvoiceTDS = (data) => API.get(`v2/Invoice/invoice_tds`, {
    params: {
        from_date: data.from_date,
        to_date: data.to_date,
        nps: data.nps,
        gis: data.gis,
        gst: data.gst,
        other: data.other,
        tds: data.tds,
        search_str: data.searchStr,
        dateColumn: data.dateColumn || '',
    }
});

// invoice_other_challans

export const getAccumulatedDeductions = (data) => API.get(`v2/Invoice/accumulated_deductions`, {
    params: {
        from_date: data.from_date,
        to_date: data.to_date,
        search_str: data.search_str || '',
        dateColumn: data.dateColumn || '',
        report: data.report || '',
    }
});

export const getTDSReports = (data) => API.get(`v2/Invoice/invoice_tds_challans`, {
    params: {
        from: data.from,
        from_date: data.from_date,
        to_date: data.to_date,
        search_str: data.searchStr || '',
        dateColumn: data.dateColumn || '',
    }
});

export const getGISReports = (data) => API.get(`v2/Invoice/invoice_gis_challans`, {
    params: {
        from_date: data.from_date,
        to_date: data.to_date,
        search_str: data.searchStr,
        dateColumn: data.dateColumn || '',
    }
});

export const getGSTReports = (data) => API.get(`v2/Invoice/invoice_gst_challans`, {
    params: {
        from_date: data.from_date,
        to_date: data.to_date,
        search_str: data.searchStr,
        dateColumn: data.dateColumn || '',
    }
});

export const getDepositoryReports = (data) => API.get(`v2/Depository/records`, {
    params: {
        from_date: data.from_date,
        to_date: data.to_date,
        search_str: data.search_str || '',
        dateColumn: data.dateColumn || '',
    }
});

export const getOTHERReports = (data) => API.get(`v2/Invoice/invoice_other_challans`, {
    params: {
        from_date: data.from_date,
        to_date: data.to_date,
        search_str: data.searchStr,
        dateColumn: data.dateColumn || '',
    }
});

export const getNPSReports = (data) => API.get(`v2/Invoice/invoice_nps_challans`, {
    params: {
        from_date: data.from_date,
        to_date: data.to_date,
        search_str: data.searchStr,
        dateColumn: data.dateColumn || '',
    }
});

export const getChallanDetails = (id) => API.get(`v2/Challans/single/${id}`);
export const updateChallan = (formData, id) => API.post(`v2/Challans/edit/${id}`, formData);
export const l1_l2_status = (formData) => API.post(`v2/Challans/l2_l3_Approval`, formData);
export const get_tds_codes = () => API.get('/v2/Invoice/tdc_codes');
// Reports
export const getInterestReports = (data) => API.get(`v2/Report/interest`, {
    params: {
        from_date: data.from_date || '',
        to_date: data.to_date || ''
    }
});
export const getBeneficiaryReports = (id, data) => API.get(`v2/Beneficiary/beneficiary_report`, {
    params: {
        beneficiary_id: id,
        from_date: data.from,
        to_date: data.to
    }
});
export const getSchemeSubheadReports = (id, data) => API.get(`v2/Schemes/schemes_subhead_report`, {
    params: {
        scheme_id: id,
        from_date: data.from,
        to_date: data.to
    }
});
export const downloadExcel = (url, data) => API.get(url, {
    params: {
        from_date: data.from_date || '',
        to_date: data.to_date || '',
        search_str: data.search_str || '',
        dateColumn: data.dateColumn || '',
    }
});

export const addTDSChallan = (formData) => API.post('v2/Invoice/create_tds_challan', formData);

export const addGISChallan = (formData) => API.post('v2/Invoice/create_gis_challan', formData);
export const addNPSChallan = (formData) => API.post('v2/Invoice/create_nps_challan', formData);

export const addOtherChallan = (formData) => API.post('v2/Invoice/create_other_challan', formData);
export const addGSTChallan = (formData) => API.post('v2/Invoice/create_gst_challan', formData);

export const getSchemeAccountBalances = (data) => API.get('v2/schemes/list_scheme_accounts', {
    params: {
        department_id: data?.department_id || '',
    }
});
export const updateSchemeBankAccountApproval = (formData, $id) => API.post(`v2/schemes/update_bank_account_approval/${$id}`, formData);
// department Module
export const get_departments = (data) => API.get('v2/multi/Department_details', {
    params: {
        department_level: data.department_level || '',
        active_scheme_account_id: data.active_scheme_account_id || '',
        searchStr: data.searchStr || '',
    }
});
export const get_departmentDetail = (data) => API.get('v2/multi/Department_details', {
    params: {
        id: data.id || '',
    }
});
export const addDepartment = (formData) => API.post('v2/multi/Department_details/create', formData);
export const editDepartment = (formData, id) => API.post(`v2/multi/Department_details/edit/${id}`, formData);
//Activity Apis
export const addActivity = (formData) => API.post('v2/multi/Activity', formData);
export const editActivity = (formData, id) => API.post(`v2/multi/Activity/edit/${id}`, formData);
export const getActivity = (id) => API.get(`v2/multi/Activity`, {
    params: {
        id: id || '',
    }
})
//Sub Activity Apis
export const AddSubActivity = (formData) => API.post('v2/multi/Activity/subactivities', formData);
export const editSubActivity = (formData, id) => API.post(`v2/multi/Activity/edit/subactivities/${id}`, formData);
export const getSubActivity = (formData) => API.get(`v2/multi/Activity/subactivities`, {
    params: {
        activity_id: formData?.id || '',
        searchStr: formData?.searchStr
    }
})

// budget Allocation
export const AddAllocateBudget = (formData) => API.post('v2/multi/Allocate_budget/create', formData);
export const UploadAllocateBudgetDoc = (formData) => API.post('v2/multi/Allocate_budget/upload_doc', formData);
export const getAllocateBudgetList = (data) => API.get(`v2/multi/Allocate_budget/fetch_allocate_budgets`, {
    params: {
        id: data?.id || '',
        pending: data?.pending || false
    }
})
export const approve_allocate_budget = (data, id) => API.post(`v2/multi/Allocate_budget/approval_status/${id}`, data);
// child Bank Account Apis

export const AddBankAccount = (formData) => API.post('v2/schemes/create_scheme_account', formData);
export const getBankAccountList = (formData) => API.get(`v2/schemes/list_scheme_accounts`, {
    params: {
        childrens: true,
        searchStr: formData.searchStr || '',
        department_level: formData.tier || '',
    }
})
// child scheme bank Account Apis
export const AddSchemeBankAccount = (formData) => API.post('v2/schemes/map_child_scheme_account', formData);
export const getSchemeBankAccountList = () => API.get(`v2/schemes/map_child_scheme_account`)

// Challan APi

export const getChallanData = (formData) => axios.create({baseURL: 'http://65.0.24.174:3100/'}).post(`get_challan_data`, formData);

export const getPPAList = (data) => API.get(`schemes/ppa`, {
    params: {
        batch_id: data?.batch_id || '',
        from_date: data.from_date || '',
        to_date: data.to_date || '',
    }
});
export const downloadPPA = (formData) => API.post(`schemes/download_ppa`, formData);


//tds Section and Rate Apis
export const get_Tds_Section = () => API.get(`sanctions_order/tds_sections`)
export const get_Tds_Rate = (id) => API.get(`sanctions_order/tds_sections_amount/${id}`);

export const send_otp_forget_password = (formData) => API.post(`users/user_forget_send_otp`, formData);
