import React, { useState, useEffect } from 'react'
import './AddDepartment.css';
import { Alert, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_DEPARTMENT_FAIL, ADD_DEPARTMENT_START, ADD_DEPARTMENT_SUCCESS, UPDATE_DEPARTMENT_START, UPDATE_DEPARTMENT_SUCCESS } from '../../../RTK/Slices/DepartmentSlice';
import { addDepartment, editDepartment, get_departmentDetail, get_departments } from '../../../helper/Api';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
const AddDepartmentForm = () => {
    const { id } = useParams();
    const { authData } = useSelector((state) => state.authData);
    const { loading } = useSelector((state) => state.user);
    // console.log(loading);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        code: null,
        name: null,
        nomiclature: null,
        department_level: 'T2',
        department_type: "",
        parent_department_id: authData.user.department_id,
        attachments: [],
    });
    // const [passwordError, setPasswordError] = useState('');
    const [department, setDepartment] = useState([]);
    const [t1Details, sett1Details] = useState({});
    const [t2Details, sett2Details] = useState({});
    const [t3Details, sett3Details] = useState({});
    const [error, setError] = useState({
        status: false,
        msg: ''
    });
    useEffect(() => {
        if (id) {
            setFormDetails(id);
        }
        fetctDetails(authData.user.department_id);
    }, [id])
    const handleFormDataChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value })
        // if (name === 'password') {
        //     setPasswordError('');
        // }
        if (name === 'department_level') {
            if (value == 'T3') {
                levelDetails('T2');
            }
        }
    }
    const handleDepartment = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value })
        fetctDetails(value);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            alert('Please fill in all fields correctly.');
            return;
        }
        try {
            dispatch(ADD_DEPARTMENT_START());
            const post_data = new FormData();
            for (let i = 0; i < formData.attachments.length; i++) {
                post_data.append('attachment[]', formData.attachments[i]);
            }
            post_data.append('code', formData.code);
            post_data.append('name', formData.name);
            post_data.append('nomiclature', formData.nomiclature);
            post_data.append('department_level', formData.department_level);
            post_data.append('parent_department_id', formData.parent_department_id);
            post_data.append('department_type', formData.department_type);
            // console.log("payload=>", formData);
            if (id) {
                dispatch(UPDATE_DEPARTMENT_START());
                const add_department_response = await editDepartment(post_data, id);
                if (add_department_response) {
                    dispatch(UPDATE_DEPARTMENT_SUCCESS(add_department_response));
                    toast.success(add_department_response.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    navigate('/departments');
                } else {
                    toast.error(add_department_response.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    dispatch(ADD_DEPARTMENT_FAIL(add_department_response.message));
                }
            }
            else {
                dispatch(ADD_DEPARTMENT_START());
                const add_department_response = await addDepartment(post_data);
                if (add_department_response.status) {
                    dispatch(ADD_DEPARTMENT_SUCCESS(add_department_response));
                    toast.success(add_department_response.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    navigate('/departments');
                } else {
                    toast.error(add_department_response.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    dispatch(ADD_DEPARTMENT_FAIL(add_department_response.message));
                }
            }
        } catch (error) {
            console.log(error);
            toast.error("error getting while add user", {
                position: toast.POSITION.TOP_CENTER
            });
            dispatch(ADD_DEPARTMENT_FAIL('error getting while add department'));
        }
    }
    const validateForm = () => {
        // Validate each field individually
        if (!formData.code.trim() ||
            !formData.name.trim() ||
            !formData.nomiclature.trim()) {
            return false; // If any field is empty, return false
        }
        // Validate password format
        // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        // if (!passwordRegex.test(formData.password)) {
        //     setPasswordError('Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.');
        //     return false;
        // }
        // Add more validation logic as needed
        return true; // All fields are valid
    };
    const handleAttachmentChange = (event) => {
        if (validateFile(event.target)) {
            const files = Array.from(event.target.files);
            console.log('ddddd', files)
            setFormData({
                ...formData,
                attachments: files
            });
        }
    };
    function validateFile(input) {
        console.log('input', input);
        const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        const maxSize = 5 * 1024 * 1024; // 5 MB

        const file = input.files[0];
        const fileSize = file.size;
        const fileType = file.type;

        if (!allowedTypes.includes(fileType)) {
            // document.getElementById('error').innerText = "Invalid file type. Please upload a PDF or DOCX file.";
            toast.error('File Type Not Allowed', {
                position: toast.POSITION.TOP_CENTER
            });
            input.value = ''; // Clear the input
            return false;
        }

        if (fileSize > maxSize) {
            toast.error('File size greater than 5MB', {
                position: toast.POSITION.TOP_CENTER
            });
            input.value = '';
            return false;
        }
        return true;

        return true;

        // If validation passed, you can do something with the file
        // console.log("File is valid:", file);
    }
    const fetctDetails = async (id) => {
        try {
            const departmentDetail = await get_departmentDetail({ id: id });
            const departObject = departmentDetail.data.result[0];
            if (departmentDetail.data.status) {
                if (departObject.department_level == 'T1') {
                    sett1Details(departObject);
                }
                if (departObject.department_level == 'T2') {
                    sett2Details(departObject);
                }
                if (departObject.department_level == 'T3') {
                    sett3Details(departObject);
                }
                setFormData(prevState => ({
                    ...prevState,
                    parent_department_id: departObject.id || authData.user.department_id,
                }));
            }
        } catch (error) {
            console.log("departments");
        }
    }
    const setFormDetails = async (id) => {
        try {
            const departmentDetail = await get_departmentDetail({ id: id });
            const departObject = departmentDetail.data.result[0];
            if (departmentDetail.data.status) {
                setFormData(prevState => ({
                    ...prevState,
                    code: departObject.code,
                    name: departObject.name,
                    nomiclature: departObject.nomiclature,
                    department_level: departObject.department_level,
                    department_type: departObject.department_type,
                    parent_department_id: departObject.parent_department_id || authData.user.department_id,
                    // attachment: prevState.attachment.concat(
                    //     departObject.letter_url ? [{ letter_url: departObject.letter_url }] : []
                    // ),
                }));
                fetctDetails(departObject.parent_department_id);
            }
        } catch (error) {
            console.log("departments", error);
        }
    }
    const levelDetails = async (value) => {
        try {
            const departments = await get_departments({ department_level: value });
            if (departments.data.status) {
                setDepartment(departments.data.result);
                fetctDetails(departments.data.result[0].id);
            }
        } catch (error) {
            console.log("get_departments error");
        }
    }
    return (
        <div>
            <div className="p-3">
                <div className="row">
                    {error.status && (
                        <Alert variant="danger" onClose={() => setError({ status: false, msg: '' })} dismissible>
                            {error.msg}
                        </Alert>
                    )}
                    <Form onSubmit={handleSubmit}>
                        <div className="col-md-12">
                            <div className="add_new_department">
                                <p>{id ? 'Edit Department' : 'Add New Department'}</p>
                                <div>
                                    <button type="button" className="btn btn-light">Cancel</button>
                                    <button type="submit" disabled={loading} className="btn btn-primary">
                                        {loading ? 'loading' : id ? 'EDIT DEPARTMENT' : 'ADD DEPARTMENT'}</button>
                                </div>
                            </div>
                            <div className="card p-3 mt-3">
                                <div className="row">
                                    <div className="col-md-12 ">
                                        {(formData.department_level !== 'T1') && <div className="table-responsive mt-4">
                                            {((formData.department_level === 'T2' && authData.user.department_details.department_level !== 'T2') || (formData.department_level === 'T3' && authData.user.department_details.department_level !== 'T3')) && <><h6 class="department_level">T1 Department Details</h6>
                                                <table className="table table-bordered table-center">
                                                    <thead>
                                                        <tr>
                                                            <th>Code</th>
                                                            <th>Name</th>
                                                            <th>Nomiclature</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{t1Details.code}</td>
                                                            <td>{t1Details.name}</td>
                                                            <td>{t1Details.nomiclature}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>
                                            }
                                            {formData.department_level === 'T4' && <><h6 class="department_level">T3 Department Details</h6>
                                                <table className="table table-bordered table-center">
                                                    <thead>
                                                        <tr>
                                                            <th>Code</th>
                                                            <th>Name</th>
                                                            <th>Nomiclature</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>001</td>
                                                            <td>John Doe</td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>
                                            }
                                        </div>}
                                        {(formData.department_level === 'T3' && authData.user.department_details.department_level !== 'T3') && <><h6 class="department_level">T2 Department Details</h6>
                                            <table className="table table-bordered table-center">
                                                <thead>
                                                    <tr>
                                                        <th>Code</th>
                                                        <th>Name</th>
                                                        <th>Nomiclature</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{t2Details.code}</td>
                                                        <td>{t2Details.name}</td>
                                                        <td>{t2Details.nomiclature}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </>
                                        }
                                        <div className="form-group row p-2">
                                            <label className="col-sm-4 col-form-label">Department Type</label>
                                            <div className="col-sm-8">
                                                <select className="form-control"
                                                    name="department_level"
                                                    value={formData.department_level}
                                                    onChange={handleFormDataChange}
                                                    required>
                                                    <option value="T2">T2</option>
                                                    <option value="T3">T3</option>
                                                </select>
                                            </div>
                                        </div>
                                        {(formData.department_level === 'T3' && authData.user.department_details.department_level !== 'T3') &&
                                            <div className="form-group row p-2">
                                                <label className="col-sm-4 col-form-label">Department</label>
                                                <div className="col-sm-8">
                                                    <select className="form-control"
                                                        name="parent_department_id"
                                                        onChange={handleDepartment}
                                                        value={formData.parent_department_id}
                                                        required>
                                                        {department.map(option => (
                                                            <option key={option.name} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>}

                                        <div className="form-group row p-2">
                                            <label for="inputEmployee" className="col-sm-4 col-form-label">
                                                {formData.department_level} Code<span className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" id="inputEmployee"
                                                    name="code"
                                                    value={formData.code}
                                                    onChange={handleFormDataChange}
                                                    required
                                                    maxLength={10}
                                                    placeholder="Enter Code" />
                                            </div>
                                        </div>
                                        <div className="form-group row p-2">
                                            <label for="inputUserid" className="col-sm-4 col-form-label">{formData.department_level} Name<span
                                                className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control"
                                                    id="inputUserid"
                                                    name="name"
                                                    required
                                                    maxLength={30}
                                                    value={formData.name}
                                                    onChange={handleFormDataChange}
                                                    placeholder="Enter Name" />
                                            </div>
                                        </div>
                                        <div className="form-group row p-2">
                                            <label for="inputEmployee" className="col-sm-4 col-form-label">
                                                {formData.department_level} Nomiclature<span className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" id="inputEmployee"
                                                    name="nomiclature"
                                                    value={formData.nomiclature}
                                                    onChange={handleFormDataChange}
                                                    required
                                                    maxLength={10}
                                                    placeholder="Enter Nomiclature" />
                                            </div>
                                        </div>
                                        <div className="form-group row p-2">
                                            <label for="inputDesignation"
                                                className="col-sm-4 col-form-label">Letter of Department<span
                                                    className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                {
                                                    !formData?.attachments?.length ? (
                                                        <label className='p-1'>
                                                            <input id="" type="file"
                                                                className='form-control'
                                                                onChange={handleAttachmentChange}
                                                                name='attachment'
                                                                accept=".pdf,.jpeg,.jpg,.png"
                                                                multiple />
                                                        </label>
                                                    ) : (
                                                        formData.attachments.map((att, i) => {
                                                            return (
                                                                <a className="mt-2 mx-2" href={att.letter_url}
                                                                    target='_blank'>Document {i + 1}</a>);
                                                        })
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddDepartmentForm
